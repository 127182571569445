<template>
  <section class="book-review__infobox">
    <div class="book-infobox__col">
      <div
        v-for="category in columns[1]"
        :key="category.name"
        class="book-infobox__item"
      >
        <strong>{{ $t(category.name) }}:</strong>
        <span class="book-infobox__text">{{ category.value }} </span>
      </div>
    </div>
    <div class="book-infobox__col">
      <div
        v-for="category in columns[2]"
        :key="category.name"
        class="book-infobox__item"
      >
        <strong>{{ $t(category.name) }}:</strong>
        <span class="book-infobox__text">{{ category.value }} </span>
      </div>
    </div>
  </section>
</template>

<script>
const LEFT_COLUMN = 1;
const RIGHT_COLUMN = 2;

export default {
  props: {
    review: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      columns: {
        [LEFT_COLUMN]: [],
        [RIGHT_COLUMN]: [],
      },

      infobox: [],
    };
  },

  computed: {
    filled() {
      return this.infobox.filter((item) => item.value);
    },

    isMulti() {
      return this.typeExists(LEFT_COLUMN) && this.typeExists(RIGHT_COLUMN);
    },
  },

  async created() {
    await this.fill();
    this.order();
  },

  methods: {
    fill() {
      this.infobox = [
        {
          name: 'Time',
          column: LEFT_COLUMN,
          value: Array.from(
            new Set(
              [
                this.review.timeFrom &&
                  (this.review.timeFrom < 0
                    ? `${+this.review.timeFrom} ${this.$t('BC')}`
                    : `${this.review.timeFrom} ${this.$t('AD')}`),
                this.review.timeTo &&
                  (this.review.timeTo < 0
                    ? `${+this.review.timeTo} ${this.$t('BC')}`
                    : `${this.review.timeTo} ${this.$t('AD')}`),
              ].filter((c) => c)
            )
          ).join(' - '),
        },
        {
          name: 'Place',
          column: LEFT_COLUMN,
          value: this.review.hasPlaces() ? this.review.getPlaceNames(this.$i18n.locale) : null,
        },
        {
          name: 'Tags',
          column: LEFT_COLUMN,
          value: this.review.tags?.join(', '),
        },
        {
          name: 'True events',
          column: RIGHT_COLUMN,
          value: this.review.TrueEvents?.map(({ name }) => name).join(', '),
        },
        {
          name: 'Real characters',
          column: RIGHT_COLUMN,
          value: this.review.RealCharacters?.map(({ name }) => name).join(', '),
        },
        {
          name: "Characters' names",
          column: RIGHT_COLUMN,
          value: this.review.CharacterNames?.map(({ name }) => name).join(', '),
        },
        {
          name: "Author's nationality",
          column: RIGHT_COLUMN,
          value: this.review.authorsNationality,
        },
        {
          name: 'Screen adaptations',
          column: RIGHT_COLUMN,
          value: this.review.ScreenAdaptations?.map(
            ({ category, name }) => `${category}: ${name}`
          ).join(',\n'),
        },
        {
          name: 'Recommended For',
          column: RIGHT_COLUMN,
          value: this.review.Recommendeds?.map(({ name }) => name)?.join(', '),
        },
      ];
    },

    order() {
      this.filled.forEach((item) => {
        if (this.isMulti) {
          this.columns[item.column].push(item);
        } else if (
          this.columns[LEFT_COLUMN].length > this.columns[RIGHT_COLUMN].length
        ) {
          this.columns[RIGHT_COLUMN].push(item);
        } else {
          this.columns[LEFT_COLUMN].push(item);
        }
      });
    },

    typeExists(type) {
      return this.filled.some((item) => item.column === type);
    },
  },
};
</script>

<i18n src="./../infobox/translations.json"></i18n>

<style lang="scss">
@import './reviewInfobox.scss';
</style>
