<template>
  <div class="select__underline">
    <vue-multiselect
      v-bind="$attrs"
      :searchable="false"
      :show-labels="false"
      :show-no-results="true"
      :show-pointer="false"
      :track-by="trackBy"
      :label="label"
      v-on="$listeners"
      @open="open"
      @close="close"
    >
      <span
        slot="caret"
        slot-scope="{ toggle }"
        class="multiselect__arrow"
        :class="{ open: isOpened }"
        @mousedown.prevent.stop="toggle"
      >
        <svg
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.46951 0.851403C9.65795 0.656966 9.65795 0.341129 9.46951 0.146198C9.28012 -0.0487327 8.97391 -0.0487327 8.78548 0.146198L4.8053 4.24665L0.828003 0.146198C0.635723 -0.0487331 0.328076 -0.0487331 0.140604 0.146198C-0.0468683 0.341128 -0.0468684 0.656965 0.140604 0.851402L4.43949 5.27904C4.53995 5.38317 4.67359 5.42758 4.8053 5.42018C4.93749 5.42758 5.07065 5.38317 5.17159 5.27904L9.46951 0.851403Z"
            fill="black"
          />
        </svg>
      </span>
      <span slot="noResult" class="t-italic">{{
        $t('No elements found')
      }}</span>
      <span slot="noOptions" class="t-italic">{{ $t('List is empty') }}</span>
      <span slot="placeholder">{{ $t('Select option') }}</span>
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
        ><slot :name="slot" v-bind="scope"
      /></template>
    </vue-multiselect>
  </div>
</template>
<i18n src="./translations.json"></i18n>

<script>
import VueMultiselect from 'vue-multiselect';

export default {
  components: {
    VueMultiselect,
  },

  props: {
    trackBy: String,
    label: String,
  },

  data() {
    return {
      isOpened: false,
    };
  },

  methods: {
    open() {
      this.isOpened = true;
    },

    close() {
      this.isOpened = false;
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
