<template>
  <div class="review-stats">
    <div class="review-stats__title">{{ $t('Community Reviews') }}</div>
    <div class="review-stats__strongs-points">
      <span>{{ $t('Strong points') }}</span> | {{ reviewPoints || 0 }}
      {{ $t('reviews') }}
    </div>
    <div class="review-stats__categories">
      <RatingCategory
        v-for="category in categories"
        :key="category.name + 'category'"
        :ico="category.ico"
        :name="$t(category.name)"
        :percent="category.percent"
        :styles-img="category.style"
        :class-name="category.class"
      />
    </div>
  </div>
</template>

<script>
import RatingCategory from '../rating-category/RatingCategory';

export default {
  components: {
    RatingCategory,
  },
  props: ['categories', 'reviewPoints'],
};
</script>

<i18n src="./translations.json"></i18n>

<style lang="scss">
@import './style.scss';
</style>
