import api from '@/http/client';
import ExandriaBook from '@/models/ExandriaBook';
import GoogleBook from '@/models/GoogleBook';
import BookTypeError from '@/exceptions/BookTypeError';

export default {
  async findByIsbnOrId(isbn, googleId) {
    const { book, bookType, averageRate } = await api.$get(`/books/${isbn}`, {
      googleId,
    });

    if (bookType === 'google') {
      const { id, saleInfo, volumeInfo } = book;
      return new GoogleBook({ id, type: 'google', ...saleInfo, ...volumeInfo });
    }

    if (bookType === 'db') {
      return new ExandriaBook({ ...book, averageRate });
    }

    throw new BookTypeError('Undefined book type');
  },
};
