import { render, staticRenderFns } from "./SingleSelectUnderline.vue?vue&type=template&id=47522ca2&"
import script from "./SingleSelectUnderline.vue?vue&type=script&lang=js&"
export * from "./SingleSelectUnderline.vue?vue&type=script&lang=js&"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./translations.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Fsprings-apps%2Fexandria%2Fexandria-front-end%2Fsrc%2Fcomponents%2Fui%2Fsingleselect%2FSingleSelectUnderline.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports