<template>
  <div v-if="hasFilledCategory" class="strong-points">
    {{ $t('Strong points') }}
    <div class="strong-points__block">
      <div
        v-for="category in categories"
        :key="category.key"
        class="strong-points__image-block"
      >
        <img
          v-if="review[category.dataKey]"
          :src="category.ico"
          alt="review-strong-point-ico"
        />
      </div>
    </div>
  </div>
</template>

<script>
import categories from '../../../../../../constants/categories';

export default {
  props: ['review'],
  data() {
    return {
      categories,
    };
  },
  computed: {
    hasFilledCategory() {
      const dataKeys = this.categories.map((ico) => ico.dataKey);

      return !!dataKeys.find((key) => this.review[key]);
    },
  },
};
</script>

<i18n src="./translations.json"></i18n>
