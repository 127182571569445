<template>
  <div class="book">
    <section class="banner">
      <div class="container">
        <h2 v-text="$t('Book')"></h2>
      </div>
    </section>
    <div v-if="book" class="page__content">
      <div class="container">
        <div class="d-flex flex-column flex-xl-row">
          <div class="book__info">
            <span class="book__label" v-text="$t('Your search')"></span>
            <div class="book__image">
              <img :src="book.getImage()" :alt="book.getTitle()" />
            </div>
            <div class="book__description">
              <h2 class="book__title" v-text="book.getTitle()"></h2>
              <em
                class="book__author"
                v-text="book.getAuthors().toString()"
              ></em>
              <read-more
                v-slot="{ toggle, isClamped, isClampable }"
                :text="book.getDescription()"
                class="book__synopsis"
                :height="233"
              >
                <div>
                  <button
                    v-if="isClampable"
                    class="btn--readmore"
                    @click="toggle"
                  >
                    {{ isClamped ? $t('Read more') : $t('Read less') }}
                  </button>
                </div>
              </read-more>
              <div class="book__first-review">
                <div
                  v-if="!book.hasReviews()"
                  class="d-flex align-items-center"
                >
                  <img src="@/assets/img/add_review.svg" alt="$t('Review')" />
                  <p v-text="$t('Be the first to review')"></p>
                </div>
                <router-link
                  class="btn btn--primary btn--review mt-3"
                  :to="$localePath('reviews.create')"
                  v-text="$t('Review it')"
                />
              </div>
            </div>
          </div>
          <div class="book__sidebar">
            <info-box :book="book" />
            <!--            <amazon :book="book" />-->
          </div>
        </div>
        <!-- <ratings :book="book" /> -->
        <ReviewStatistic
          v-if="book.hasReviews()"
          :review-points="book.Reviews.length"
          :categories="categoriesArray"
        />
        <div v-if="book.hasReviews()" class="book__footer">
          <filters
            :book="book"
            :countries="countries"
            class="d-none d-xl-block"
          />
          <reviews :book="book" :countries="countries" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import book from '@/api/book';
import countries from '@/api/nationalities';
import ReadMore from '@/components/readMore/ReadMore';
// import Amazon from '@/components/amazon/Amazon';
import InfoBox from './components/infobox/InfoBox';
// import Ratings from './components/ratings/Ratings';
import Filters from './components/filters/Filters';
import Reviews from './components/reviews/Reviews';
import ReviewStatistic from '../../../components/ui/review-statistic/ReviewStatistic';
import categories from '../../../constants/categories';

export default {
  components: {
    // Amazon,
    Filters,
    InfoBox,
    // Ratings,
    ReadMore,
    Reviews,
    ReviewStatistic,
  },

  data() {
    return {
      book: null,
      countries: [],
    };
  },

  computed: {
    categoriesArray() {
      if (!this.book.averageRate) {
        return null;
      }

      return categories.map((category) => ({
        ...category,
        percent: this.book.averageRate[category.id],
      }));
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      return Promise.all([this.fetchBook(), this.fetchCountries()]);
    },

    async fetchBook() {
      const { isbn } = this.$route.params;
      const { googleId } = this.$route.query;
      this.book = await book.findByIsbnOrId(isbn, googleId);
    },

    async fetchCountries() {
      const countriesData = await countries.get();
      this.countries = ['', ...Object.values(countriesData)];
    },
  },
};
</script>

<i18n src="./translations.json"></i18n>

<style lang="scss">
@import './book.scss';
</style>
