<template>
  <section class="book-reviews">
    <button
      class="d-xl-none btn btn--primary btn--filters mb-5"
      @click="isModalVisible = true"
      v-text="$t('Filter reviews')"
    ></button>
    <div v-for="review in reviewsVisible" :key="review.id" class="book-review">
      <div class="review-author__image">
        <img :src="review.user.avatar()" alt="Avatar" />
      </div>
      <div class="w-100">
        <div class="book-review__header">
          <span
            class="review-author__name"
            v-text="review.user.username || review.user.firstName"
          ></span>
          <stars
            v-if="review.rating()"
            class="review--md book-review__rating"
            :rate="review.rating()"
          />
          <span
            v-if="review.createdDate()"
            class="book-review__date"
            v-text="review.createdDate().format('DD/MM/YYYY')"
          ></span>
        </div>
        <review-infobox :review="review" />
        <strong-points :review="review" />
        <read-more
          v-slot="{ toggle, isClamped, isClampable }"
          :text="review.comment"
          class="book-review__text"
          :height="132"
        >
          <div>
            <button v-if="isClampable" class="btn--readmore" @click="toggle">
              {{ isClamped ? $t('Read more') : $t('Read less') }}
            </button>
          </div>
        </read-more>
        <comments :review="review" />
      </div>
    </div>
    <button
      v-if="!expanded && reviews.length > 2"
      class="btn btn--secondary btn--view-all"
      @click="expanded = true"
      v-text="$t('View All')"
    ></button>

    <modal v-show="isModalVisible" @close="isModalVisible = false">
      <div slot="content">
        <filters :book="book" :countries="countries" />
      </div>
    </modal>
  </section>
</template>

<script>
import reviews from '@/api/review';
import Stars from '@/components/stars/Stars';
import ReadMore from '@/components/readMore/ReadMore';
import Modal from '@/components/modal/Modal';
import Comments from '../comments/Comments';
import ReviewInfobox from '../reviewInfobox/ReviewInfobox';
import Filters from '../filters/Filters';
import StrongPoints from './components/StrongPoints';

export default {
  components: {
    Comments,
    Filters,
    Modal,
    ReadMore,
    ReviewInfobox,
    Stars,
    StrongPoints,
  },

  props: {
    book: {
      type: Object,
      required: true,
    },

    countries: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      reviews: [],
      expanded: false,
      isModalVisible: false,
    };
  },

  computed: {
    reviewsVisible() {
      return this.expanded ? this.reviews : this.reviews.slice(0, 2);
    },
  },

  watch: {
    '$route.query': {
      handler() {
        this.fetchReviews();
      },
      deep: true,
    },
  },

  created() {
    this.fetchReviews();
  },

  methods: {
    async fetchReviews() {
      this.reviews = await reviews.get(this.book.getISBN(), this.$route.query);
    },
  },
};
</script>

<i18n src="./translations.json"></i18n>

<style lang="scss">
@import './reviews.scss';
</style>
