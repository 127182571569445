<template>
  <section class="review-filters">
    <div class="review-filter">
      <div class="review-filter__header">
        <strong v-text="$t('Age')"></strong>
        <label class="form-check__label mb-0">
          <input
            v-model="allAgesSelected"
            class="form-check__input"
            type="checkbox"
            tabindex="1"
            @change="selectAllAges"
          />
          <span class="form-check__indicator" tabindex="0"></span>
          {{ $t('Select All') }}
        </label>
      </div>
      <label v-for="age in ages" :key="age" class="form-check__label">
        <input
          v-model="query.ages"
          class="form-check__input"
          type="checkbox"
          :value="age"
          tabindex="1"
          @change="filterByAge"
        />
        <span class="form-check__indicator" tabindex="0"></span>
        {{ $t(age) }}
      </label>
    </div>
    <div class="review-filter">
      <div class="review-filter__header">
        <strong v-text="$t('Gender')"></strong>
        <label class="form-check__label mb-0">
          <input
            v-model="allGendersSelected"
            class="form-check__input"
            type="checkbox"
            tabindex="1"
            @change="selectAllGenders"
          />
          <span class="form-check__indicator" tabindex="0"></span>
          {{ $t('Select All') }}
        </label>
      </div>
      <label v-for="gender in genders" :key="gender" class="form-check__label">
        <input
          v-model="query.genders"
          class="form-check__input"
          type="checkbox"
          :value="gender"
          tabindex="1"
          @change="filterByGender"
        />
        <span class="form-check__indicator" tabindex="0"></span>
        {{ $t(gender) }}
      </label>
    </div>
    <div class="review-filter">
      <label
        class="form-label"
        @mousedown.prevent="setFocus('nationality')"
        v-text="$t('Nationality')"
      ></label>
      <single-select-underline
        ref="nationality"
        v-model="query.nationality"
        :options="countries"
        :placeholder="$t('italian')"
        :allow-empty="false"
        @input="filter"
      />
    </div>
  </section>
</template>

<script>
import SingleSelectUnderline from '@/components/ui/singleselect/SingleSelectUnderline';

export default {
  components: {
    SingleSelectUnderline,
  },

  props: {
    book: {
      type: Object,
      required: true,
    },

    countries: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      query: {
        ages: [],
        genders: [],
        nationality: undefined,
      },
      allAgesSelected: false,
      allGendersSelected: false,
      ages: [
        '16-17',
        '18-24',
        '25-29',
        '30-39',
        '40-49',
        '50-64',
        'over_65',
        'not_available',
      ],
      genders: ['male', 'female', 'other'],
    };
  },

  computed: {
    parseAge() {
      return this.$route.query.age ? this.$route.query.age.split(',') : [];
    },

    parseGender() {
      return this.$route.query.gender
        ? this.$route.query.gender.split(',')
        : [];
    },

    queryAge() {
      return this.query.ages.length ? this.query.ages.join(',') : undefined;
    },

    queryGender() {
      return this.query.genders.length
        ? this.query.genders.join(',')
        : undefined;
    },

    queryNationality() {
      return !this.empty(this.query.nationality)
        ? this.query.nationality
        : undefined;
    },

    searchQuery() {
      return {
        age: this.queryAge,
        gender: this.queryGender,
        nationality: this.queryNationality,
      };
    },

    nextRoute() {
      return {
        name: this.$route.name,
        params: this.$route.params,
        query: this.searchQuery,
      };
    },

    nextPath() {
      const { name, params, query } = this.nextRoute;

      return this.$router.resolve({ name, params, query });
    },

    isCurrentLocation() {
      return this.$route.fullPath === this.nextPath;
    },
  },

  created() {
    this.parseQuery();
    this.render();
  },

  methods: {
    parseQuery() {
      this.query = {
        ages: this.parseAge,
        genders: this.parseGender,
        nationality: this.$route.query.nationality,
      };
    },

    filterByAge() {
      this.updateSelectAllAgesCheckbox();
      this.filter();
    },

    filterByGender() {
      this.updateSelectAllGendersCheckbox();
      this.filter();
    },

    filter() {
      if (this.isCurrentLocation) {
        return;
      }

      this.$router.push(this.nextRoute);
    },

    selectAllAges(e) {
      this.query.ages = e.target.checked ? this.ages : [];
      this.filter();
    },

    selectAllGenders(e) {
      this.query.genders = e.target.checked ? this.genders : [];
      this.filter();
    },

    render() {
      this.updateSelectAllAgesCheckbox();
      this.updateSelectAllGendersCheckbox();
    },

    updateSelectAllAgesCheckbox() {
      this.allAgesSelected = this.ages.every((age) =>
        this.query.ages.includes(age)
      );
    },

    updateSelectAllGendersCheckbox() {
      this.allGendersSelected = this.genders.every((gender) =>
        this.query.genders.includes(gender)
      );
    },

    setFocus(ref) {
      this.$nextTick(() => {
        const el = this.$refs[ref].$el.querySelector('.multiselect');

        if (el) {
          el.focus();
        }
      });
    },

    empty(value) {
      return value === '' || value === undefined || value === null;
    },
  },
};
</script>

<i18n src="./translations.json"></i18n>

<style lang="scss">
@import './filters.scss';
</style>
