<template>
  <div :class="['rating-category', className || '']">
    <div class="rating-category__name">{{ name }}</div>
    <div class="rating-category__ico">
    <img :src="ico" alt="ico" :style="stylesImg || {}" />
      <svg
        width="104"
        height="56"
        viewBox="0 0 104 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          :class="['bar', 'active']"
          x1="8.74228e-08"
          y1="55"
          x2="32"
          y2="55"
          stroke-width="2"
        />
        <line
          :class="['bar', { active: 1 <= filledBarsCount }]"
          y1="-1"
          x2="32"
          y2="-1"
          transform="matrix(0.951057 0.309017 -0.309442 0.950918 2 40)"
          stroke-width="2"
        />
        <line
          :class="['bar', { active: 2 <= filledBarsCount }]"
          x1="9.58779"
          y1="23.191"
          x2="35.4763"
          y2="42.0001"
          stroke-width="2"
        />
        <line
          :class="['bar', { active: 3 <= filledBarsCount }]"
          y1="-1"
          x2="32.0041"
          y2="-1"
          transform="matrix(0.587785 0.809017 -0.809359 0.587314 21 11)"
          stroke-width="2"
        />
        <line
          :class="['bar', { active: 4 <= filledBarsCount }]"
          x1="36.0097"
          y1="2.34772"
          x2="45.8982"
          y2="32.7815"
          stroke-width="2"
        />
        <line
          :class="['bar', { active: 5 <= filledBarsCount }]"
          x1="53.0508"
          y1="32"
          x2="53.0508"
          y2="2.76472e-06"
          stroke-width="2"
        />
        <line
          :class="['bar', { active: 6 <= filledBarsCount }]"
          x1="60.1544"
          y1="32.9078"
          x2="70.043"
          y2="2.47397"
          stroke-width="2"
        />
        <line
          :class="['bar', { active: 7 <= filledBarsCount }]"
          y1="-1"
          x2="32.0041"
          y2="-1"
          transform="matrix(0.587785 -0.809017 -0.809359 -0.587314 65.1094 35.584)"
          stroke-width="2"
        />
        <line
          :class="['bar', { active: 8 <= filledBarsCount }]"
          y1="-1"
          x2="32"
          y2="-1"
          transform="matrix(0.809017 -0.587785 -0.588414 -0.80856 69.793 41.6904)"
          stroke-width="2"
        />
        <line
          :class="['bar', { active: 9 <= filledBarsCount }]"
          y1="-1"
          x2="32"
          y2="-1"
          transform="matrix(0.951057 -0.309017 -0.309442 -0.950918 71.4805 48.2046)"
          stroke-width="2"
        />
        <line
          :class="['bar', { active: 10 <= filledBarsCount }]"
          y1="-1"
          x2="32"
          y2="-1"
          transform="matrix(-1 8.73538e-08 8.74918e-08 1 104 56)"
          stroke-width="2"
        />
      </svg>
      <div class="rating-category__percent">{{ percent.toFixed(0) }}%</div>
    </div>
  </div>
</template>

<script>
import getFiledBarsCounter from './getFiledBarsCount';

export default {
  props: ['name', 'ico', 'percent', 'stylesImg', 'className'],
  computed: {
    filledBarsCount() {
      return getFiledBarsCounter(10, this.percent);
    },
  },
};
</script>

<style lang="scss">
@import './style.scss';
</style>
