<template>
  <section class="book-infobox">
    <h3>{{ $t('About the book') }}</h3>
    <table>
      <tbody>
        <tr v-if="book.hasGenres()" class="book-infobox__item">
          <th>{{ $t('Genre') }}:</th>
          <td>{{ book.getGenres().toString() }}</td>
        </tr>
        <tr v-if="book.hasTime()" class="book-infobox__item">
          <th>{{ $t('Time') }}:</th>
          <td>{{ book.getTime().toString() }}</td>
        </tr>
        <tr v-if="book.hasPlaces()" class="book-infobox__item">
          <th>{{ $t('Place') }}:</th>
          <td>{{ book.getPlaceNames($i18n.locale).toString() }}</td>
        </tr>
        <tr v-if="book.hasCharacterNames()" class="book-infobox__item">
          <th>{{ $t("Characters' names") }}:</th>
          <td>{{ book.getCharacterNames().toString() }}</td>
        </tr>
        <tr v-if="book.hasTrueEvents()" class="book-infobox__item">
          <th>{{ $t('True events') }}:</th>
          <td>{{ book.getTrueEvents().toString() }}</td>
        </tr>
        <tr v-if="book.hasRealCharacters()" class="book-infobox__item">
          <th>{{ $t('Real characters') }}:</th>
          <td>{{ book.getRealCharacters().toString() }}</td>
        </tr>
        <tr v-if="book.hasTags()" class="book-infobox__item">
          <th>{{ $t('Tags') }}:</th>
          <td>{{ book.getTags().toString() }}</td>
        </tr>
        <tr v-if="book.hasScreenAdaptations()" class="book-infobox__item">
          <th>{{ $t('Screen adaptations') }}:</th>
          <td>
            {{ book.getScreenAdaptations((name) => $t(name)).toString() }}
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
export default {
  props: {
    book: {
      type: Object,
      required: true,
    },
  },
};
</script>

<i18n src="./translations.json"></i18n>

<style lang="scss">
@import './infobox.scss';
</style>
