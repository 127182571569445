import api from '@/http/client';
import Review from '@/models/Review';
import Comment from '@/models/Comment';

export default {
  async getReviews(isbn, query) {
    return api.$get(`/books/${isbn}/reviews/filter`, query);
  },

  async get(isbn, query) {
    const reviews = await api.$get(`/books/${isbn}/reviews/filter`, query);

    return reviews.map((review) => new Review(review));
  },

  async create(isbn, payload) {
    return api.$post(`/books/${isbn}/reviews`, payload);
  },

  async addComment(reviewId, payload) {
    const comments = await api.$post(`/reviews/${reviewId}/comments`, payload);

    return new Comment(comments[0]);
  },

  count() {
    return api.$get('/books/reviews/count');
  },
};
