<template>
  <div>
    <span ref="text">{{ text }}</span>
    <slot v-bind="{ toggle, isClamped, isClampable }" />
  </div>
</template>

<script>
import shave from 'shave';

export default {
  props: {
    text: {
      type: String,
      default: '',
    },

    height: {
      type: Number,
      default: 59,
    },
  },

  data() {
    return {
      isClamped: undefined,
      isClampable: undefined,
    };
  },

  mounted() {
    this.toggle().then(() => {
      this.isClampable = this.isClamped === true;
    });
  },

  methods: {
    toggle() {
      if (this.isClamped) {
        this.expand();
      } else {
        this.clamp();
      }

      return this.update();
    },

    expand() {
      this.$nextTick(() => {
        this.shave(this.$refs.text, 9999);
      });
    },

    clamp() {
      this.$nextTick(() => {
        this.shave(this.$refs.text, this.height);
      });
    },

    update() {
      return new Promise((resolve) => {
        this.$nextTick(() => {
          this.isClamped = this.$el.querySelector('.js-shave') !== null;
          resolve();
        });
      });
    },

    shave,
  },
};
</script>
