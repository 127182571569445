<template>
  <div class="comments">
    <div v-for="comment in comments" :key="comment.id" class="comment">
      <div class="comment-author__image">
        <img :src="comment.user.avatar()" alt="Avatar" />
      </div>
      <div>
        <div class="comment-author__name" v-text="comment.user.username"></div>
        <p class="comment-author__text" v-text="comment.text"></p>
      </div>
    </div>
    <validation-observer
      v-slot="{ handleSubmit, invalid, validated }"
      ref="form"
      tag="div"
    >
      <form @submit.prevent="handleSubmit(reply)">
        <validation-provider
          v-slot="{ errors }"
          class="form-group"
          :name="$t('Comment')"
          rules="max:500|required"
          tag="div"
        >
          <div class="form-validation w-100">
            <textarea
              v-model="form.comment"
              class="form-control"
              name="message"
              rows="5"
              :placeholder="$t('Add your comment here')"
            ></textarea>
            <span
              v-if="errors.length"
              class="form-group__error mt-1"
              v-text="errors[0]"
            ></span>
          </div>
          <div class="comment-textarea__avatar">
            <img :src="user.avatar()" alt="Avatar" />
          </div>
        </validation-provider>
        <div class="form-control__avatar"></div>
        <button
          class="btn btn--primary btn--send"
          :class="{ 'btn--disabled': (validated && invalid) || loading }"
          :disabled="loading"
          v-text="$t('Reply')"
        />
      </form>
    </validation-observer>
  </div>
</template>

<script>
import reviews from '@/api/review';
import { mapGetters } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },

  props: {
    review: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      form: {
        comment: '',
      },
      comments: [],
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      loggedIn: 'auth/loggedIn',
      user: 'user/userModel',
    }),
  },

  created() {
    this.comments = this.review.comments;
  },

  methods: {
    async reply() {
      try {
        const comment = await reviews.addComment(this.review.id, {
          comment: this.form.comment,
          parentId: 0,
        });

        this.comments.push(comment);
        this.form.comment = '';

        this.$nextTick(() => {
          this.$refs.form.reset();
        });
      } catch (e) {
        // console.log(e);
      }
    },
  },
};
</script>

<i18n src="./translations.json"></i18n>

<style lang="scss">
@import './comments.scss';
</style>
