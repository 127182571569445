<template>
  <div class="review review-rate" :class="`review--${rate.toFixed() * 2}`">
    <div class="review__stars">
      <div class="review__star review__star--full"></div>
      <div class="review__star review__star--empty"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Stars',
  props: {
    rate: {
      type: [Number, String],
      default: 0,
    },
  },
};
</script>
