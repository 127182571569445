import api from '@/http/client';

export default {
  getNationalities() {
    return api.$get(`/countries`);
  },

  get() {
    return api.$get(`/countries`);
  },
};
