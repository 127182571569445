import OriginalityIco from '../assets/img/rating-categories/originality.svg';
import PlotIco from '../assets/img/rating-categories/plot.svg';
import WritingStyleIco from '../assets/img/rating-categories/writingStyle.svg';
import EmotionIco from '../assets/img/rating-categories/emotion.svg';

export default [
  {
    name: 'Originality',
    dataKey: 'rateOriginality',
    ico: OriginalityIco,
    id: 'originality',
  },
  {
    name: 'Plot',
    dataKey: `ratePlot`,
    ico: PlotIco,
    id: 'plot',
  },
  {
    name: 'Writing style',
    dataKey: `rateWritingStyle`,
    ico: WritingStyleIco,
    style: { right: '13%' },
    id: 'writingStyle',
  },
  {
    name: `Emotional\ninvolvement`,
    dataKey: `rateEmotion`,
    ico: EmotionIco,
    id: 'emotion',
  },
];
